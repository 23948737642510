import { Fragment } from 'react'
import styled from '@emotion/styled'

const ImageContainer = styled.div`
  position: relative;
  overflow: ${(props) => (props.overflow ? `${props.overflow}` : 'hidden')};
  width: ${(props) => (props.setWidth ? `${props.setWidth}px` : '100%')};
  height: ${(props) => (props.setHeight ? `${props.setHeight}px` : '100%')};
  border: ${(props) => props.border && 'solid transparent'};
  border-width: ${(props) => props.border && '20px 20px 0'};
  aspect-ratio: ${(props) => props.aspectRatio ?? '2/1'};
`

const Picture = styled.picture`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: none;
  max-width: none;
  width: 100%;
  height: 100%;
  padding: ${(props) => (props.border ? '20px' : 0)};
`

const Image = styled('img', {
  shouldForwardProp: (prop) => prop,
})`
  height: 100%;
  object-fit: ${({ imageBehaviour }) =>
    imageBehaviour === 'contain' ? 'contain' : 'cover'};
  object-position: ${({ imageBehaviour }) =>
    imageBehaviour === 'cover' && 'center'};
  width: 100%;
`

const AspectImage = (props) => {
  const {
    src,
    url,
    border,
    setWidth,
    setHeight,
    title,
    description,
    aspectRatio,
    imageBehaviour,
    overflow,
    fetchPriority, // auto, high, low
    aboveTheFold = false,
  } = props

  const imageUrl = `${src || url}`
  const urlParam = `${imageUrl}?q=75`
  const formatParam = '&fm=webp'

  const widths = [
    { image: 1200, viewport: 1025 },
    { image: 1024, viewport: 769 },
    { image: 768, viewport: 501 },
    { image: 500, viewport: null },
  ]

  return (
    <ImageContainer
      setWidth={setWidth}
      setHeight={setHeight}
      border={border}
      aspectRatio={aspectRatio}
      overflow={overflow}
    >
      <Picture>
        {widths.map((width, index) => {
          const imageWidth = width.image
          const imageViewport = width.viewport
          const widthParam = `&w=${imageWidth}`
          const widthParam2x = `&w=${2 * imageWidth}`

          const mediaQueryStandard = imageViewport
            ? `(min-width: ${imageViewport}px)`
            : undefined

          const mediaQueryHighDef = imageViewport
            ? `(min-width: ${imageViewport}px) and (-webkit-min-device-pixel-ratio: 2), (min-width: ${imageViewport}px) and (min-resolution: 192dpi)`
            : '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'

          return (
            <Fragment key={index}>
              <source
                media={mediaQueryHighDef}
                srcSet={`${urlParam}${formatParam}${widthParam2x}`}
                type="image/webp"
              />

              <source
                media={mediaQueryStandard}
                srcSet={`${urlParam}${formatParam}${widthParam}`}
                type="image/webp"
              />
            </Fragment>
          )
        })}

        {/* Fallback */}
        <Image
          alt={title || description}
          title={description || title}
          imageBehaviour={imageBehaviour}
          loading={aboveTheFold ? 'eager' : 'lazy'}
          fetchPriority={fetchPriority}
          src={`${urlParam}`}
        />
      </Picture>
    </ImageContainer>
  )
}

export default AspectImage
